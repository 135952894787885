<template>
  <v-input
    :label="label"
    :rules="rules"
    :error-messages="validaCampo"
    v-model="valorInterno"
    class="InputDecimal v-text-field"
    :class="{focused, dirty, alignRight}"
    :required="required"
    :disabled="disabled"
    :hint="hint"
    :persistent-hint="persistentHint"
    :dense="dense"
    :single-line="singleLine"
    :hide-details="hideDetails"
    :append-icon="appendIcon"
    @click:append="clickAppend">
    <money
      v-model="valorInterno"
      v-bind="decimal"
      :id="id"
      @input="valorAlterado"
      :disabled="disabled">
    </money>
    <span v-if="suffix" :class="suffixClass" style="white-space: nowrap;">{{ suffix }}</span>
  </v-input>
</template>
<script>
import { Money } from 'v-money';

export default {
  name: 'InputDecimal',
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: String,
    id: {
      type: String,
      default: () => `InputDecimal-${Date.now().toString()}`,
    },
    rules: {
      type: Array,
    },
    hint: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: Number,
      default: 2,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      default: null,
    },
    suffixClass: {
      type: String,
      default: '',
    },
    negative: {
      type: Boolean,
      default: true,
    },
    zero: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Money,
  },
  watch: {
    value: {
      immediate: true,
      handler(alterado) {
        this.valorInterno = alterado;
      },
    },
  },
  computed: {
    hasErrors() {
      if (!this.required) {
        return false;
      }

      const val = this.valorInterno,
        hasErrors = (val === null || (!this.zero && val === this.valorVazio));
      return hasErrors;
    },
    validaCampo() {
      if (this.hasErrors) {
        return this.$t('message.campo_obrigatorio');
      }
      return null;
    },
    decimal() {
      return {
        decimal: ',',
        thousands: '.',
        precision: this.precision,
        masked: false,
      };
    },
  },
  data() {
    return {
      dirty: false,
      focused: false,
      valorInterno: null,
      valorVazio: 0,
    };
  },
  methods: {
    valorAlterado(valor) {
      if (valor === this.value) return;
      this.$emit('InputDecimal_valorAlterado', valor);
      this.$emit('input', valor);
      if (!this.negative && valor < 0) {
        setTimeout(() => this.$emit('input', Math.abs(valor)), 2E2);
      }
    },
    refresh() {
      this.$forceUpdate();
    },
    clickAppend() {
      this.$emit('InputDecimal_clickAppend');
    },
  },
  mounted() {
    const input = document.getElementById(this.id);

    input.addEventListener('focus', () => {
      this.focused = true;
    }, false);

    input.addEventListener('keyup', () => {
      this.dirty = true;
    }, false);

    input.addEventListener('blur', () => {
      this.focused = false;
      this.dirty = true;
    }, false);
  },
};
</script>

<style>
.InputDecimal.v-input--is-disabled .v-input__slot:before {
  border-image: repeating-linear-gradient(90deg,rgba(0,0,0,.38) 0,rgba(0,0,0,.38) 2px,transparent 0,transparent 4px) 1 repeat;
}
.InputDecimal:not(.dirty).error--text .v-input__slot:before {
  border-color: rgba(0,0,0,.42);
}
.InputDecimal:not(.dirty).error--text input {
  caret-color: rgba(0,0,0,.42);
}
.InputDecimal.dirty.error--text .v-input__slot:before {
  border-color: #b71c1c;
}
.InputDecimal.dirty.error--text input {
  caret-color: #b71c1c;
}
.InputDecimal:not(.focused){
  color: rgba(0,0,0,.42) !important;
}
.InputDecimal .v-input__slot {
  transition: background .3s cubic-bezier(.25,.8,.5,1);
}
.InputDecimal .v-input__slot:before {
  border-color: currentColor;
  border-style: solid;
  border-width: thin 0 0;
}
.InputDecimal.error--text:not(.dirty) .v-input__slot:after {
  border-color: rgba(0,0,0,.42) !important;
}
.InputDecimal .v-input__slot:after {
  border-color: currentColor;
}
.InputDecimal .v-input__slot:after {
  border-style: solid;
  border-width: thin 0;
  transform: scaleX(0);
}
.InputDecimal .v-input__slot:before,
.InputDecimal .v-input__slot:after {
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: .3s cubic-bezier(.25,.8,.5,1);
  width: 100%;
}
.InputDecimal.focused .v-input__slot:after {
  transform: scaleX(1);
}
.InputDecimal:not(.dirty) .v-input__slot .v-label {
  color: rgba(0,0,0,.54) !important;
}
.InputDecimal .v-input__slot .v-label {
  position: absolute !important;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 6px;
  transform-origin: top left;
  white-space: nowrap;
}
.InputDecimal .v-input__slot .v-label {
  max-width: 100%;
  transform: translateY(-18px) scale(.75);
}
.InputDecimal .v-input__slot .v-label:not(.error--text) {
  color: currentColor !important;
}
.InputDecimal.v-input input:invalid,
.InputDecimal.v-input input:required {
  box-shadow: none;
}
.InputDecimal.v-input input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0;
  max-width: 100%;
  width: 100%;
}
.InputDecimal.alignRight.v-input input {
  text-align: right;
}
</style>
